.nav {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 1rem;
  background: tranpsparent;
  width: 100%;
  height: 90px;

  z-index: 1;
  transition: var(--transition);
  position: fixed;
}

.nav-scroll {
  background: var(--color-bg-dark);
  transition: var(--transition);
}

.logo-text {
  display: flex;
  gap: 5px;
  font-size: 20px;
  font-weight: 700;
  color: var(--color-white) !important;
  white-space: nowrap;
}

.nav-menu {
  display: flex;
  justify-content: right;
  width: 80%;
}

.nav-menu > a {
  padding: 0 1rem;
  font-size: 1.2rem;
  font-weight: 500;
  color: var(--color-light);
}

.nav-menu > a:hover {
  color: var(--color-white);
}

.nav-menu > a.active {
  color: var(--color-white);
}

@media screen and (max-width: 1040px) {
  .nav-menu {
    display: none;
  }
}
@media screen and (max-width: 640px) {
  .logo-text {
    font-size: 1rem;
  }
}

/*

.nav-menu {
    flex-direction: column;
    justify-content: center;
    align-items: center;
    width: 100%;
    height: 100vh;
    background: rgba(0, 0, 0, 0.9);
    position: absolute;
    top: 0;
    right: -100%;
    z-index: -3;
    transition: var(--transition);
  }

  .nav-menu > a {
    padding: 1rem;
    font-size: 2rem;
  }

  .nav-menu.active {
    right: 0;
  }

  .hamburger {
    display: initial;
  }
*/
