@import url("https://fonts.googleapis.com/css2?family=Outfit:wght@100;200;300;400;500;600;700;800;900&display=swap");

:root {
  --color-bg: #2e3134;
  --color-bg-dark: #202124;
  --color-bg-varient: #353944;
  --color-primary: #2e3134;
  --color-primary-variant: #4db5ff;
  --color-white: #fff;
  --color-light: rgba(255, 255, 255, 0.6);

  --transition: all 400ms ease;
}

html {
  scroll-behavior: smooth;
  height: -webkit-fill-available;
}

::-webkit-scrollbar {
  display: none;
}

* {
  box-sizing: border-box;
  margin: 0;
  padding: 0;
}

body {
  font-family: "Outfit", sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}

a {
  transition: var(--transition);
  text-decoration: none;
}

section {
  background: var(--color-bg);
  color: var(--color-white);
}

.btn {
  background-color: var(--color-primary);
  width: 120px;
  display: flex;
  justify-content: center;
  color: var(--color-primary-variant);
  padding: 0.75rem 1.2rem;
  border-radius: 0.4rem;
  cursor: pointer;
  border: 1px solid var(--color-primary-variant);
}

.btn:hover {
  background: transparent;
}
