body {
  background-color: rgba(52, 53, 65, 1);
}

.first-word{
    color: white;
    font-size: 60px;
    opacity: 0;
    animation: fadeInFromLeft 1s ease-out forwards;
    animation-delay: 2s;
  }

.second-word{
  color: white;
  font-size: 60px;
  opacity: 0;
  animation: fadeInFromRight 1s ease-out forwards;
  animation-delay: 4s;
}

.custom-container {
  width: 100%;
  height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}

@keyframes fadeInFromRight {
    from {
      opacity: 0;
      transform: translateX(100px);
    }
  
    to {
      opacity: 1;
      transform: translateX(0);
    }
  }

  @keyframes fadeInFromLeft {
    from {
      opacity: 0;
      transform: translateX(-100px);
    }
  
    to {
      opacity: 1;
      transform: translateX(0);
    }
  }