.header-container {
  display: flex;
  flex-direction: column;

  height: 100vh;
  width: 100%;
  background: transparent;
}

video {
  width: 100%;
  height: 100vh;
  background-size: cover;
  background-position: center;
  object-fit: cover;
  z-index: -1;
  position: fixed;
}

.scroll-background {
  height: 100vh;
  width: 100%;
}

.scroll-background::after {
  content: "";
  width: 100%;
  height: 100vh;
  position: absolute;
  top: 0;
  left: 0;
  background-color: var(--color-bg-varient);
  opacity: 0.7;
}

.header-container > .content {
  position: absolute;
  transform: translate(-50%, -50%);
  top: 50%;
  left: 50%;
  text-align: center;
  white-space: nowrap;
}

.header-container > .content > h1 {
  font-size: 4rem;
  padding: 0.6rem 0 1.5rem;
}

.header-container > .content > h5 {
  font-size: 2rem;
  font-weight: 200;
  padding: 0.6rem 0 1.5rem;
}

.header-button-container {
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 10px;
  gap: 10px;
}

@media screen and (max-width: 640px) {
  .header-container > .content > h1 {
    font-size: 2rem;
  }

  .header-container > .content > h5 {
    font-size: 1rem;
  }

  .header-container > .content > .btn {
    font-size: 1rem;
  }

  .header-button-container {
    flex-direction: column;
  }
}
