.experience-container {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  padding-top: 100px;
  padding-bottom: 100px;
  min-height: 800px;
  gap: 30px;
}

.experience-container > h1 {
  padding: 40px;
  font-size: 2rem;
}

.experience-card-container {
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 30px;
}

.experience-card {
  border: 1px solid var(--color-primary-variant);
  background-color: var(--color-primary);
  transition: var(--transition);
  color: var(--color-light);
  padding: 0.75rem 1.2rem;
  border-radius: 0.4rem;
  cursor: pointer;
  top: 0;
  position: relative;
  text-align: center;
  width: 250px;
  height: 300px;
}

.experience-card:hover {
  top: -20px;
}

.experience-card-header {
  font-size: 30px;
}

.experience-card-header > h1 {
  font-size: 25px;
}

.experience-card-header > h6 {
  font-size: 15px;
}

.experience-card-body {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 60%;
  flex-direction: column;
  font-size: 17px;
  gap: 5px;
}

@media screen and (max-width: 1040px) {
  .experience-card-container {
    flex-direction: column;
  }
}
